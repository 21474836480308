import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import { api as api2 } from '../mainNode'

const createReceiptOPDGet = async id => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'order/check',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('get drug list in receipt err : ', err)

      return {}
    })

  return response
}

const createReceiptOPDCartListGet = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'order/cartlist',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('get order cart list err : ', err)

      return {}
    })

  return response
}

// Node api
const createReceiptOPDSave = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'order/checkv2',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('create order opd err : ', err)

      return {}
    })

  return response
}

// PHP api
const receiptCreate = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'order/v2',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('create receipt  err : ', err)

      return {}
    })

  return response
}

export default { createReceiptOPDGet, createReceiptOPDCartListGet, createReceiptOPDSave,receiptCreate }
